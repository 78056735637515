import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { FORM_ERROR } from 'final-form';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { Card, Heading, Link } from 'rebass';
import { compose } from 'redux';

import Button from 'components/Button';
import FormError from 'components/FormError';
import Input from 'components/Input';
import { USER_ROLE } from 'redux/enums';
import * as auth from 'redux/features/auth';
import { setTokens } from 'utils/auth';
import createFormError from 'utils/createFormError';
import { checkRequired } from 'utils/validation';

const SignIn = ({ obtainToken, history }) => (
  <Card variant="authCard" width={1}>
    <Heading textAlign="center" mb={4}>
      Sign In
    </Heading>
    <Form
      onSubmit={async (body) => {
        const result = await obtainToken({
          body,
        });

        if (result.error) {
          return createFormError(result);
        }

        if (![USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN].includes(result.payload.user.userType)) {
          return {
            [FORM_ERROR]: 'Forbidden',
          };
        }

        setTokens(result.payload);

        history.push('/');
      }}
      subscription={FormError.formSubscriptions}
    >
      {({ handleSubmit, ...meta }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="email"
            component={Input}
            type="email"
            autoComplete="email-address"
            validate={checkRequired}
            label="Email Address"
            placeholder="Enter email address"
          />
          <Field
            name="password"
            component={Input}
            type="password"
            autoComplete="password"
            validate={checkRequired}
            label="Password"
            placeholder="Enter password"
          />
          <Field name="token" component={Input} type="number" label="OTP Token" placeholder="000000 (optional)" />
          <FormError meta={meta} />
          <Button loading={meta.submitting} type="submit" mt={3}>
            Sign In
          </Button>
          <Link
            mt={3}
            display="block"
            as="a"
            href={process.env.REACT_APP_RESET_PASSWORD_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p
              css={css`
                text-align: center;
              `}
            >
              Forgot password?
            </p>
          </Link>
        </form>
      )}
    </Form>
  </Card>
);

SignIn.propTypes = {
  obtainToken: PropTypes.func.isRequired,
  history: PropTypes.object,
};

export default compose(
  connect(null, {
    obtainToken: auth.obtainToken,
  }),
  React.memo
)(SignIn);
